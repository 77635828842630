<template>
  <div class="home">
    <ConstructionTemplate msg="Under construction..."/>
  </div>
</template>

<script>
// @ is an alias to /src
import ConstructionTemplate from '@/components/ConstructionTemplate.vue'

export default {
  name: 'HomeView',
  components: {
    ConstructionTemplate
  }
}
</script>
